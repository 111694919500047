@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 360px:          smallphone
361px - 600px:      phone
601px - 960px:      tablet
961px - 1280px:     laptop
1281px - 1920px:    desktop
min-width 1921px:   large desktop

$breakpoint arguement choices:
-smallphone
-phone
-tablet
-laptop
-desktop
-largedesktop

*/
@mixin respond($breakpoint) {
//360
    @if $breakpoint==smallphone {
        @media only screen and (max-width: 576px)  {
            @content;
        }
    }
//600
    @if $breakpoint==phone {
        @media only screen and (min-width: 577px) and (max-width: 767px) {
            @content;
        }
    }

//960
    @if $breakpoint==tablet {
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }
//1024
    @if $breakpoint==laptop {
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    }

    //1280
    @if $breakpoint==desktop {
        @media only screen and (min-width: 1200px) and (max-width: 1440px) {
            @content;
        }
    }

    @if $breakpoint==largedesktop {
        @media only screen and (min-width: 1441px) {
            @content;
        }
    }
}
