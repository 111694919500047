/* You can add global styles to this file, and also import other style files */
@import 'scss/abstracts/functions';
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/variables';

@import 'scss/base/base';
@import 'scss/base/home';
@import 'scss/base/typography';

@import 'scss/layout/footer';
@import 'scss/layout/header';
@import 'scss/layout/navigation';

// Styles only used for static capabilities

* {
  font-family: 'NotoSans-Regular', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  background-color: var(--body_color);
}

ol,
ul,
li {
  list-style: none;
}

.wrpr {
  width: 1200px;
  margin: 0 auto;
}

// Typography styles

h1 {
  font-size: 24px;
  font-family: 'NotoSans-Bold', sans-serif;
  color: var(--black);
}

h2 {
  font-size: 20px;
  font-family: 'NotoSans-Bold', sans-serif;
  color: var(--black);
}

h3 {
  font-size: 18px;
  font-family: 'NotoSans-Bold', sans-serif;
  color: var(--black);
}

p {
  font-size: 14px;
  font-family: 'NotoSans-Regular', sans-serif;
  color: var(--black);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.autocomplete-container {
  height: 56px !important;
  box-shadow: 0 0 0.875rem #00000026 !important;
  .input-container input {
    height: 56px !important;
    .x {
      top: 72% !important;
    }
  }
}
