// // COLORS
:root {
    --body_color: #fbfaff;
    --black: #1a1a1a;
    --white: #ffffff;
    --white1: #fafafa;
    --grey: #787878;
    --grey2: #707070;
    --grey3:#a8a8a8;
    --red: #ff0000;
    --dark-blue: #12379b;
    --light-blue: #F7FAFF;
    --light-grey: #979797;
    --light-grey2: #9d9d9d;
    --light-grey3: #e0e0e0;
    --btn-green: #127c72;
    --like-btn-yellow: #FCE500;
    --bg-overlay: #00000030
}