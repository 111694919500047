@media (min-width: 1920px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1512px !important;
  }
}

body {
  background-color: var(--body_color);
}

.background_radial1,
.background_radial3 {
  width: 300px;
  height: 300px;
  border-radius: 250px;
  position: absolute;
  background-image: radial-gradient(#ffd6c5, var(--body_color));
  box-shadow: 0 0 8px 8px var(--body_color) inset;
  opacity: 0.5;
  filter: blur(50px);
}

.background_radial2,
.background_radial4 {
  width: 300px;
  height: 300px;
  border-radius: 250px;
  position: absolute;
  background-image: radial-gradient(#d0c5fd, var(--body_color));
  box-shadow: 0 0 8px 8px var(--body_color) inset;
  opacity: 0.5;
  filter: blur(50px);
}

.background_radial1 {
  left: 50px;
  z-index: 1;
}

.background_radial2 {
  left: 80px;
  bottom: 142px;
  z-index: 1;
}

.background_radial3 {
  right: 50px;
  top: 300px;
  z-index: 1;
}

.background_radial4 {
  right: 80px;
  top: 700px;
  z-index: 1;
}

.display_none {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.36;
  cursor: no-drop;
}

button[disabled] {
  opacity: 0.36;
  cursor: no-drop;
}

.primary_green_btn {
  width: fit-content;
  height: 48px;
  padding: 14px 38px;
  border-radius: 5px;
  background-color: var(--btn-green);
  color: var(--white);
  text-transform: uppercase;
  font-family: NotoSans-Bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.secondary_green_btn {
  width: fit-content;
  height: 48px;
  padding: 14px 38px;
  border-radius: 5px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  color: var(--btn-green);
  text-transform: uppercase;
  font-family: NotoSans-Bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  border: none;
}

.tertiary_green_btn {
  width: fit-content;
  height: 48px;
  padding: 14px 0px;
  color: var(--btn-green);
  font-family: NotoSans-Bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tertiary_green_link_btn {
  width: fit-content;
  color: var(--btn-green);
  font-family: NotoSans-Regular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.border_green_btn {
  display: flex;
  width: fit-content;
  height: 48px;
  padding: 14px 32px;
  color: var(--btn-green);
  font-family: NotoSans-Regular;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border: solid 1px var(--btn-green);
  border-radius: 5px;
  gap: 8px;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.progress {
  height: 4px;
  width: 318px;
}

.progress-bar {
  background-color: var(--dark-blue);
}

:focus-visible {
  outline: none !important;
}

.asterisk {
  color: var(--red);
}

.btn {
  border: none !important;
}

// Styles for message on empty containers for tabs starts

.messageContainer,
.message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--black);
  gap: 24px;

  .title {
    font-family: NotoSans-Bold !important;
    font-size: 24px;
  }

  .subtitle {
    font-family: NotoSans-Regular !important;
    font-size: 14px;
  }
}

.messageContainer > img {
  margin-bottom: 80px;
}

.message {
  gap: 24px;
}

// Styles for message on empty containers for tabs ends
