@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('NotoSans-Bold', '../fonts/NotoSans-Bold');
@include font('NotoSans-BoldItalic', '../fonts/NotoSans-BoldItalic1');
@include font('NotoSans-Italic', '../fonts/NotoSans-Italic');
@include font('NotoSans-Regular', '../fonts/NotoSans-Regular');
@include font('Roboto-Regular', '../fonts/Roboto-Regular');
